import React, { useEffect, useState } from "react";
import styled,{keyframes} from "styled-components";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";
import { Icon } from "semantic-ui-react";

import { v4 as uuidv4 } from "uuid";

import { CSSTransition, TransitionGroup } from "react-transition-group"; // new

import {
  SG_START_BRIEF_SURVEY,
  NEW_VALIDATION_TOKEN,
  RESET_VALIDATION_TOKEN,
  CLEAR_ALL,
  SG_POST_BRIEF_RESPONSE,
  // UPDATE_BRIEF_RESPONSE,
  SG_UPDATE_BRIEF_RESPONSE,
  CLEAR_CHAT_GPT_SUMMARY,
  // SG_GET_ALL_MODULAR_QUESTIONS,
} from "constants/actions";

import {
  // BrowserView,
  // MobileView,
  isBrowser,
  isMobile,
  deviceType,
  osName,
  osVersion,
  deviceDetect,
} from "react-device-detect";

import {
  introductionTypeQuestion,
  categoryTypeQuestion,
  questionTypeQuestion,
  feedbackTypeQuestion,
  personalityTypeQuestion,
  modularOutcomeTypeQuestion,
  modularValuesTypeQuestion,
  modularCommentTypeQuestion,
  // commentsType,
  // questionsType,
  ModularSurveyQuestionTypes,
  personalityQuestionList,
  introSectionTypeQuestion,
  followupAiTypeQuestion,
  multiAiFollowupCommentQuestion, // feedback_builder multiAiFollowupCommentQuestion
  completedTypeQuestion,
  submitTypeQuestion,
  commentTypeQuestion,
  submitSurvey,
  nextQuestionBtn,
  introductionBtn,
} from "constants/questionTypes";

import {
  MISSING_OR_INVALID_TOKEN,
  ERROR_ON_SUBMISSION,
  THANKYOU_MESSAGE,
} from "constants/messaging";

import { AuditRunRules } from "utilities/FeedbackRules";
import { ModularSurveyCheck } from "utilities/ModularRules";
import AuditExistingDataFound from "survey/SurveyComponents/AuditExistingData";

// new survey
import Question from "./questionTypes/Question";

import ProgressBar from "./Components/ProgressBar";

import Feedback from "./questionTypes/Feedback";
import Categories from "./questionTypes/Categories";
import Introduction from "./Introduction";
import "./index.css";

import NavigationButtons from "./Components/NavigationButtons";
import FollowUp from "./chatGpt/FollowUp";
import MultiFollowUp from "./chatGpt/MultiFollowUp";
import Submit from "./questionTypes/Submit";
import IntroSection from "./IntroSection";

import Completed from "./questionTypes/Completed";
import Language from "./Language";
import Comments from "./questionTypes/Comment";
import QuestionModular from "./questionTypes/QuestionOutcome";
import QuestionComments from "./questionTypes/QuestionComments";
import PersonalityChoice from "./questionTypes/PersonalityChoice";

import QuestionValues from "./questionTypes/QuestionValues";
import LogoAnimation from "survey/SurveyComponents/LogoAnimations";
import {words} from './translations'
import { sort } from "semver";
import { get } from "http";


// const categoryType = "category"
let LOCAL_VALIDATION_TOKEN = localStorage.getItem("validation_token");


const arrange_Questions = (questions) => {
  if (questions?.dimensions) {
    return questions.dimensions
      .map((item, i) => {
        return item.factors.map((factor, f) => {
          return factor.questions.map((q, idx) => {
            return {
              question: q.q,
              dimension: i,
              factor: f,
              q: idx,
              role: q.role,
              reverse: q.reverse,
              order: q.id,
              fr: "fr" in q ? q.fr : null,
              es: "es" in q ? q.es : null,
              title: questions?.question_titles ? factor.title : false,
              qualifier: q?.qualifier,
              scale: q?.scale,
            };
          });
        });
      })
      .flat()
      .flat()
      .sort((a, b) => a.order - b.order);
  }
};

const AuditSurvey = () => {
  const dispatch = useDispatch();
  const deviceDetected = deviceDetect();

  let { token } = useParams();

  // eslint-disable-next-line no-unused-vars
  let [searchParams, setSearchParams] = useSearchParams();
  const direct_language = Number(searchParams.get("language"));

  const [noResponse, setNoResponse] = useState(false);
  // const [loading, setLoading] = useState(true)

  const [ruleFeedback, setRuleFeedback] = useState(false);
  const [orgRules, setOrgRules] = useState(false);

  const [delayedShow, setDelayedShow] = useState(true);

  //
  const [questionSequenceMap, setQuestionSequenceMap] = useState(false);
  const [totalQuestions, setTotalQuestions] = useState({
    total: 0,
    runningTotal: 0,
    questions: 0,
    culture: 0,
    sequence: {},
  });

  const [showingStoredResponse, setShowingStoredResponse] = useState(false);
  const [questionList, setQuestionList] = useState([]);
  const [categoryQues, setCategoryQues] = useState([]);
  const [employeeCategory, setEmployeeCategory] = useState([]);
  const [showLanguage, setShowLanguage] = React.useState(false);
  const [languages, setLanguages] = React.useState([]);

  // stored persisting response
  // const [validationToken, setValidationToken] = useState(false)
  const [briefResponseId, setBriefResponseId] = useState(false);
  const [storedResponseFound, setStoredResponseFound] = useState(false);
  //
  const [briefResponse, setBriefResponse] = useState([]);
  const [storedResponse, setStoredResponse] = useState(false);
  const [questionNo, setQuestionNo] = useState(0);
  const [visualQuestionNo, setVisualQuestionNo] = useState(0);
  //
  // const [prevResponse, setPrevResponse] = useState({})
  //
  const [checkRole, setCheckRole] = useState(false);

  const [questionAnswers, setQuestionAnswers] = useState({
    categories: {},
    questions: {},
    comments: {},
  });

  const [formPayload, setFormPayload] = useState(false);
  // const [thankMessage, setThankMessage] = useState(false)

  const [surveyStatus, setSurveyStatus] = useState(0);
  const [surveyDeleted, setSurveyDeleted] = useState(false);
  const [surveyDeletedOn, setSurveyDeletedOn] = useState(false);

  const [primaryCategory, setPrimaryCategory] = useState(false);
  // const [surveyName, setSurveyName] = useState(false)
  const [neverAskResponse, setNeverAskResponse] = useState(false);
  const [prevFoundResponse, setPrevFoundResponse] = useState(false);

  const [organizationName, setOrganizationName] = useState("");

  const [language, setLanguage] = useState("EN");

  const [askComments, setAskComments] = useState(false);

  const [submitError, setSubmitError] = useState(false);
  const [organizationInfo, setOrganizationInfo] = useState(false);
  const [surveyStandards, setSurveyStandards] = useState(false);
  //
  const [surveyFeedbackResp, setSurveyFeedbackResp] = useState(false);
  const [surveyPersonalityResp, setSurveyPersonalityResp] = useState({});
  const [surveyModularResp, setSurveyModularResp] = useState(false);
  // in_survey_personality
  const [showInSurveyPersonality, setShowInSurveyPersonality] = useState(true);

  const [askNextQuestion, setAskNextQuestion] = useState(false);
  //
  const [modSurveyRule, setModSurveyRule] = useState([]);
  // //new
  const [currentQuestionDet, setCurrentQuestionDet] = useState({});
  const [currentQuestionNo, setCurrentQuestionNo] = useState(1);
  const [direction, setDirection] = useState("slide");
  const [isVisible, setIsVisible] = useState(true);
  const [surveyFinished, setSurveyFinished] = useState(false);
  // //new
  const [storedTitle, setStoredTitle] = useState("");
  const [teamName, setTeamName] = useState("");

  const [progressBarColor, setProgressBarColor] = useState("#f5f5f5");
  const [sortOrder,setSortOrder] = useState(0)
  const[disableNext,setDisableNext] = useState(false)
  const [height, setHeight] = useState(window.innerHeight);

  const { get_brief_response, get_errors } = useSelector(
    (state) => ({
      get_brief_response: state.briefresponse,
      get_errors: state.errors,
    }),
    shallowEqual
  );

  

  useEffect(() => {
    const updateHeight = () => {
      setHeight(window.innerHeight);
    };

    window.addEventListener("resize", updateHeight);

    // Set the initial height based on viewport
    updateHeight();

    // Cleanup event listener on unmount
    return () => {
      window.removeEventListener("resize", updateHeight);
    };
  }, []);

  useEffect(() => {
    if (direct_language === 1) {
      setLanguage('FR');
    }
    if(direct_language === 2){
      setLanguage('ES');
    }
  }, [direct_language]);

  // useEffect(() => {
  // 	setTimeout(() => {
  // 		setLoading(false)
  // 	}, 1500)
  // }, [])

  useEffect(() => {
    const RuleRun = AuditRunRules(questionList, orgRules, questionAnswers,surveyStandards);
    setRuleFeedback(RuleRun);
  }, [
    orgRules,
    questionList,
    questionAnswers,
    currentQuestionNo,
    showingStoredResponse,
    surveyStandards,
  ]);

  // console.log("orgRules", orgRules)
  // console.log("RuleFeedback", ruleFeedback);  
  useEffect(() => {
    setTimeout(() => {
      if (get_brief_response?.validation_token === null) {
        // console.log("NEW_VALIDATION_TOKEN updated **************************");
        const _newToken = uuidv4();
        localStorage.setItem("validation_token", _newToken);
        LOCAL_VALIDATION_TOKEN = _newToken;
      } 
    }, 500); // 1000 milliseconds = 1 second
  }, [get_brief_response.validation_token]);

  // console.log("modSurveyRule", modSurveyRule)
  // console.log("orgRules", orgRules)
  // // console.log("questionAnswers", questionAnswers)
  // console.log("get_brief_response?.response?.stored_response", get_brief_response?.response?.stored_response)
  // console.log("storedResponseFound", storedResponseFound)
  // console.log("storedResponse", storedResponse)
  // console.log("get_brief_response?.response?.stored_response", get_brief_response?.response?.stored_response)

  // console.log("storedResponse validation_token", storedResponse?.validation_token)
  // console.log("get_brief_response?.validation_token",get_brief_response?.validation_token)
  // console.log(questionSequenceMap)
  // console.log("formPayload",formPayload)
  // console.log("survey standards", get_brief_response?.response?.survey_standards)
  
  // ModularSurveyCheck
  // console.log("ModularSurveyRule get_brief_response", get_brief_response)
  useEffect(() => {
    if (get_brief_response?.surveyComponents) {
      console.log(get_brief_response)
      const surveyComponents = get_brief_response?.surveyComponents;
      setModSurveyRule(ModularSurveyCheck({ surveyComponents }));
    }
  }, [get_brief_response.surveyComponents]);

  useEffect(() => {
    setSurveyStandards(get_brief_response?.response?.survey_standards);
  },[get_brief_response.response.survey_standards])

  useEffect(()=>{
    setSortOrder(get_brief_response?.response?.organization_services?.find(f=>f.name=='Audit')?.question_sort_order)
  },[get_brief_response.response.organization_services])

  // // modSurveyRule
  // useEffect(() => {
  //   if (!surveyDeleted) {
  //     if (modSurveyRule && token) {
  //       modSurveyRule.map((ms) => {
  //         dispatch({
  //           type: SG_GET_ALL_MODULAR_QUESTIONS,
  //           payload: `?token=${token}&component=${ms?.value}`,
  //         });
  //         return null;
  //       });
  //     }
  //   }
  // }, [dispatch, modSurveyRule, token, surveyDeleted]);

  // console.log("get_brief_response?.modular_question ===========", modSurveyRule)
  // console.log("get_brief_response?.modular_question ===========", get_modular_questions)
  // console.log(get_brief_response)
  // console.log(get_brief_response?.visual_question)
  // console.log(totalQuestions)
  // console.log("questionSequenceMap", questionSequenceMap)

  // console.log("show not have In Survey Personality enabled")
  useEffect(() => {
    const SurveyPersonality =
      get_brief_response?.response?.organization_services?.filter(
        (os) => os.name === "In Survey Personality"
      )?.[0];
    // console.log(SurveyPersonality)
    // console.log("should not contain in_survey_personality")
    // console.log(get_brief_response?.response?.employee_survey_spec?.['survey_personality'])
    const sp_earlier =
      get_brief_response?.response?.employee_survey_spec?.[
        "survey_personality"
      ];
    if (SurveyPersonality?.enabled === true) {
      // personality completed ealier dont ask again but inject in the response
      if (sp_earlier) {
        setShowInSurveyPersonality(false);
        // setSurveyPersonalityResp(sp_earlier?.survey_personality)
      } else {
        // console.log(get_brief_response?.response?.employee_survey_spec)
        // personality survey completed and refreshing and collection from store data
        if (get_brief_response?.in_survey_personality) {
          setShowInSurveyPersonality(false);
          // setSurveyPersonalityResp(get_brief_response?.survey_personality)
        } else {
          setShowInSurveyPersonality(true);
        }
      }
    } else {
      setShowInSurveyPersonality(false);
    }
  }, [
    get_brief_response.organization_services,
    get_brief_response.in_survey_personality,
    get_brief_response.survey_personality,
    get_brief_response.response.employee_survey_spec,
    get_brief_response.response.organization_services,
  ]);

  // console.log("surveyFeedbackResp", surveyFeedbackResp)
  // console.log(get_brief_response?.response?.stored_response)
  // // console.log("surveyModularResp", surveyModularResp)

  useEffect(() => {
    if (get_brief_response?.response?.stored_response?.answers) {
      setQuestionAnswers(
        get_brief_response?.response?.stored_response?.answers
      );
    }
    if (formPayload?.feedback?.length > 0) {
      setSurveyFeedbackResp(formPayload?.feedback);
    } else {
      setSurveyFeedbackResp(
        get_brief_response?.response?.stored_response?.feedback
      );
    }
    if (get_brief_response?.response?.stored_response?.modular_response) {
      setSurveyModularResp(
        get_brief_response?.response?.stored_response?.modular_response
      );
    }
    if (get_brief_response?.response?.stored_response?.survey_personality) {
      setSurveyPersonalityResp(
        get_brief_response?.response?.stored_response?.survey_personality
      );
    }
  }, [get_brief_response.response.stored_response, formPayload.feedback]);

  useEffect(() => {
    setQuestionList(arrange_Questions(get_brief_response?.response?.questions));
    setBriefResponse(get_brief_response);
    //

    setOrgRules(get_brief_response?.response?.organization_info?.rules);
    //
    setSurveyDeleted(
      get_brief_response?.to_delete ? get_brief_response?.to_delete : false
    );
    setSurveyDeletedOn(
      get_brief_response?.deleted_on ? get_brief_response?.deleted_on : false
    );
    setSurveyStatus(
      get_brief_response?.survey_status ? get_brief_response?.survey_status : 0
    );

    setOrganizationName(get_brief_response?.response?.organization_name);
    if (get_brief_response?.response?.employee_category) {
      if (get_brief_response?.response?.organization_info?.organization == 1) {
        setOrganizationName(
          `${get_brief_response?.response?.organization_name}`
        );
      }
      else{
        const EmpPrimary = get_brief_response?.response?.employee_category.find(
          (ec) => ec?.priority === "primary"
        )?.value;
        setTeamName(EmpPrimary);

        if(get_brief_response?.response?.organization_info?.organization == 2){
          setOrganizationName(
            `${EmpPrimary}`
          );
        }
        else{
          setOrganizationName(
            `${get_brief_response?.response?.organization_name} - ${EmpPrimary}`
          );
        }
      }
    }

    let _id = "";
    let _response = 0;
    let subfactorFound = false;
    let _subfactor = null;
    const SurveyName = get_brief_response?.response?.survey_name;
    // setSurveyName(get_brief_response?.response?.survey_name)
    if (get_brief_response?.response?.primary_question) {
      get_brief_response.response.primary_question.map((pq) => {
        _id = pq.id;
        if (pq?.subfactor) {
          subfactorFound = true;
        }
        pq &&
          pq.answers.map((aq) => {
            if (aq?.name === SurveyName) {
              if (subfactorFound) {
                _subfactor = aq?.id;
                _response = aq?.parent_id;
              } else {
                _response = aq?.id;
              }
            }
            return null;
          });
        return null;
      });
    }

    setBriefResponseId(get_brief_response?.id);
    setPrimaryCategory({
      id: _id,
      response: _response,
      subfactor: _subfactor,
      priority: "primary",
    });
  }, [get_brief_response]);

  useEffect(() => {
    if (get_brief_response?.response?.questions?.title) {
      setStoredTitle(get_brief_response?.response?.questions?.title);
    }

    if (get_brief_response?.response?.questions?.language) {
      // console.log(get_brief_response)
      if(get_brief_response?.response?.questions?.languages){
        setLanguages(get_brief_response?.response?.questions?.languages);
      
      }
      setShowLanguage(true);
      
      // console.log(get_brief_response)
    }
  }, [get_brief_response]);

  // question no based on role skip logic
  // step 1 set role
  useEffect(() => {
    if (questionAnswers?.categories) {
      const CatRes = Object.values(questionAnswers?.categories).filter(
        (value) => value !== null
      );
      setCheckRole(
        CatRes.filter((ct) => ct.priority === "secondary")?.[0]?.response
      );
    }
    // console.log("setCheckRole changed")
  }, [questionAnswers]);

  // // Ask Comments
  useEffect(() => {
    const _askComments = [];
    if (get_brief_response?.response?.questions?.comments) {
      if (get_brief_response?.response?.questions?.comments?.length > 0) {
        get_brief_response.response.questions.comments.map((ac, i) => {
          return _askComments.push({ ...ac, id: i + 1 });
        });
      }
    }
    // console.log("setAskComments changed")
    setAskComments(_askComments);
  }, [get_brief_response.response.questions]);

  // console.log(get_brief_response?.response?.culture_questions)
  useEffect(() => {
    if (get_brief_response?.response) {
      // console.log("setQuestionList categoryQues setEmployeeCategory setOrganizationInfo changed")

      setEmployeeCategory(get_brief_response?.response?.employee_category);
      setOrganizationInfo(get_brief_response?.response?.organization_info);

      setCategoryQues(
        get_brief_response?.response?.culture_questions?.sort(
          (a, b) => a?.id - b?.id
        )
      );

      //Do something here to set the language component
      if (get_brief_response?.response?.questions?.language) {
        console.log(
          "get_brief_response?.response?.questions?.language",
          get_brief_response?.response?.questions?.language
        );
      }
    }
  }, [get_brief_response.response]);

  // console.log(storedResponse?.validation_token === get_brief_response?.response?.validation_token)
  // console.log("VALIDATION_TOKEN ", VALIDATION_TOKEN )
  // console.log("get_brief_response?.response?.validation_token", get_brief_response?.response?.validation_token)

  // console.log("et_brief_response?.response?.stored_response?.high_answer > 4", get_brief_response?.response?.stored_response?.high_answer > 4)
  // console.log(get_brief_response?.response?.stored_response)

  // primary Response
  useEffect(() => {
    setStoredResponseFound(false);
    if (get_brief_response?.response?.validation_token) {
      if (get_brief_response?.response?.stored_response?.high_answer > 4) {
        // if clicked once it showing_stored_response === true, show only if its false
        // it resets to false on refresh
        const uuid_str = (get_brief_response?.response?.validation_token || "")
          .toLowerCase()
          .trim();
        const valid_token = LOCAL_VALIDATION_TOKEN?.toLowerCase().trim();

        if (showingStoredResponse === false) {
          // console.log("I am heree eeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee")
          if (uuid_str === valid_token) {
            // console.log("matched uuid_str === valid_token +++++++++++++++++++++++++++++++++")
            setStoredResponseFound(true);
            setStoredResponse(get_brief_response?.response?.stored_response);
          }
        }
      }
    }
  }, [get_brief_response.response, showingStoredResponse]);

  // the core list for managing survey within the app
  useEffect(() => {
    const _seq = {};

  
    let visualTotal = 0;
    const resetResponsesOnChange = {};
    const hockeyIntro = organizationInfo?.public_intro === "hockey";

    let running_total = 0;
    let total = 0;
    let introQuestion = 0;

    if(hockeyIntro){
      _seq[1] = {
        id: 1,
        sort_order: 1,
        quesType: introductionTypeQuestion,
        buttonType: introductionBtn,
        intro_id: 6,
        total: 1,
        skip: false,
        buttonText: "Start",
        visualTotal,
        clicked: true,
        response: { response: true },
      };

      _seq[2] = {
        id: 2,
        sort_order: 2,
        intro_id: 4,
        total: 2,
        quesType: introductionTypeQuestion,
        buttonType: introductionBtn,
        buttonText: "I agree",
        survey_sort_order: sortOrder,
        visualTotal,
        clicked: true,
        response: { response: true },
      };


      running_total = 2;
      total = 2;
      introQuestion = 2;

    }
    else{
      _seq[1] = {
        id: 1,
        sort_order: 1,
        quesType: introductionTypeQuestion,
        buttonType: introductionBtn,
        intro_id: 1,
        total: 1,
        skip: false,
        buttonText: "Start",
        visualTotal,
        clicked: true,
        response: { response: true },
      };
      _seq[2] = {
        id: 2,
        sort_order: 2,
        quesType: introductionTypeQuestion,
        buttonType: introductionBtn,
        intro_id: 2,
        total: 2,
        skip: false,
        buttonText: "Next",
        visualTotal,
        clicked: true,
        response: { response: true },
      };
      _seq[3] = {
        id: 3,
        sort_order: 3,
        quesType: introductionTypeQuestion,
        buttonType: introductionBtn,
        intro_id: 3,
        total: 3,
        buttonText: "Next",
        visualTotal,
        clicked: true,
        response: { response: true },
      };
      _seq[4] = {
        id: 4,
        sort_order: 4,
        intro_id: 4,
        total: 4,
        quesType: introductionTypeQuestion,
        buttonType: introductionBtn,
        buttonText: "I agree",
        survey_sort_order: sortOrder,
        visualTotal,
        clicked: true,
        response: { response: true },
      };
  
      running_total = 4;
      total = 4;
      introQuestion = 4;
    }

    

    if(sortOrder === 160){
      _seq[5] = {
        id: 5,
        sort_order: 5,
        intro_id: 5,
        total: 5,
        quesType: introductionTypeQuestion,
        buttonType: introductionBtn,
        buttonText: "I agree",
        visualTotal,
        clicked: true,
        response: { response: true },
      };

      total+=1;
      running_total+=1;
      introQuestion+=1;
    }

   
    let CatLength = 0;
    // ai feedback questions
    let feedbackLength = 0;

    const _prevResp = {};
    const _neverAskResp = {};

    if (categoryQues) {
      let catQuesNo = 0;
      categoryQues.map((q, v) => {
        if (q?.input === "always_ask") {
          // console.log(q)
          running_total += 1;
          visualTotal += 1;
          CatLength += 1;
          total += 1;
          catQuesNo += 1;
          _seq[running_total] = {
            id: running_total,
            from: `always_ask ${q.id}`,
            category_question_id: q.id,
            quesType: categoryTypeQuestion,
            buttonType: nextQuestionBtn,
            skip: false,
            sno: running_total,
            catQuesNo,
            visualTotal,
            clicked: false,
            response: false,
            buttonText: "Next",
          };
          return null;
        }
        // change this to ids
        if (q?.input === "ask_if_not_found") {
          // setPrevFoundResponse
          const _empCat = employeeCategory.filter(
            (ec) => ec?.name_id === q.id
          )?.[0]?.value_id;
          if (_empCat) {
            // console.log(_empCat)
            // found dont ask
            const _response = q.answers.filter((rq) => rq?.id === _empCat)?.[0]
              ?.id;
            running_total += 1;

            CatLength += 1;
            total += 0;
            catQuesNo += 1;
            _seq[running_total] = {
              id: running_total,
              from: `ask_if_not_found ${q.id}`,
              category_question_id: q.id,
              quesType: categoryTypeQuestion,
              buttonType: nextQuestionBtn,
              skip: true,
              sno: running_total,
              catQuesNo,
              visualTotal,
              clicked: false,
              category_id: q.id,
              response: { response: _response },
              priority: q.priority,
              buttonText: "Next",
            };
            _prevResp[running_total] = {
              id: q.id,
              response: _response,
              priority: q.priority,
            };
          } else {
            //
            running_total += 1;
            CatLength += 1;
            visualTotal += 1;
            total += 1;
            catQuesNo += 1;
            _seq[running_total] = {
              id: running_total,
              from: `ask_if_not_found  ${q.id}`,
              category_question_id: q.id,
              quesType: categoryTypeQuestion,
              buttonType: nextQuestionBtn,
              skip: false,
              sno: running_total,
              catQuesNo,
              visualTotal,
              clicked: false,
              response: false,
              buttonText: "Next",
            };
          }
          return null;
        }
        // change this to ids
        // not shown in total
        if (q?.input === "never_ask") {
          const _empCat = employeeCategory.filter(
            (ec) => ec?.name_id === q.id
          )?.[0]?.value_id;
          running_total += 1;
          CatLength += 1;
          total += 0;
          catQuesNo += 1;
          _seq[running_total] = {
            id: running_total,
            from: `never_ask  ${q?.id}`,
            category_question_id: q.id,
            quesType: categoryTypeQuestion,
            buttonType: nextQuestionBtn,
            skip: true,
            sno: running_total,
            catQuesNo,
            visualTotal,
            clicked: false,
            category_id: q.id,
            response: { response: _empCat ? _empCat : null },
            priority: q.priority,
            buttonText: "Next",
          };

          _neverAskResp[running_total] = {
            id: q.id,
            response: _empCat ? _empCat : null,
            priority: q.priority,
          };
          return null;
        }
        // no input type found show in total
        if (!q?.input) {
          running_total += 1;
          CatLength += 1;
          total += 1;
          visualTotal += 1;
          catQuesNo += 1;
          _seq[running_total] = {
            id: running_total,
            from: `undefined`,
            category_question_id: q.id,
            quesType: categoryTypeQuestion,
            buttonType: nextQuestionBtn,
            skip: false,
            sno: running_total,
            catQuesNo,
            visualTotal,
            clicked: false,
            response: false,
            buttonText: "Next",
          };
        }
        return null;
      });

      // category completed
      if(total>4){
        running_total += 1;
        total += 1;
        _seq[running_total] = {
          id: running_total,
          sort_order: running_total,
          quesType: categoryTypeQuestion,
          total: running_total,
          visualTotal,
          skip: false,
          buttonType: nextQuestionBtn,
          organizationName:organizationName,
          clicked: true,
          response: { response: true },
          buttonText: "Next"
        };
      }
     
    }

    setPrevFoundResponse(_prevResp);
    setNeverAskResponse(_neverAskResp);

    let quesLength = 0;
    if (questionList) {
      let quesListNo = 0;
      questionList.map((q, v) => {
        if (checkRole) {
          if (q?.role) {
            if (q?.role.includes(checkRole)) {
              running_total += 1;
              quesLength += 1;
              total += 1;
              visualTotal += 1;
              quesListNo += 1;
              _seq[running_total] = {
                id: running_total,
                quesType: questionTypeQuestion,
                buttonType: nextQuestionBtn,
                from: "question for specific role",
                skip: false,
                sno: running_total,
                quesListNo,
                visualTotal,
                clicked: false,
                response: false,
                buttonText: "Next",
              };
              return null;
            } else {
              running_total += 1;
              // quesLength += 1
              quesListNo += 1;
              _seq[running_total] = {
                id: running_total,
                quesType: questionTypeQuestion,
                buttonType: nextQuestionBtn,
                from: "question not included role specifics",
                skip: true,
                sno: running_total,
                quesListNo,
                visualTotal,
                response: false,
                buttonText: "Next",
              };
              return null;
            }
          } else {
            running_total += 1;
            quesLength += 1;
            total += 1;
            visualTotal += 1;
            quesListNo += 1;
            _seq[running_total] = {
              id: running_total,
              quesType: questionTypeQuestion,
              buttonType: nextQuestionBtn,
              from: "question without role",
              skip: false,
              sno: running_total,
              quesListNo,
              visualTotal,
              clicked: false,
              response: false,
              buttonText: "Next",
            };
            return null;
          }
        } else {
          running_total += 1;
          quesLength += 1;
          total += 1;
          visualTotal += 1;
          quesListNo += 1;
          _seq[running_total] = {
            id: running_total,
            quesType: questionTypeQuestion,
            buttonType: nextQuestionBtn,
            from: "no role checked",
            skip: false,
            sno: running_total,
            quesListNo,
            visualTotal,
            clicked: false,
            response: false,
            buttonText: "Next",
          };
          return null;
        }
      });
    }

    let maxRows =
      get_brief_response?.response?.organization_info?.rules?.filter(
        (ru) => ru?.type === "max"
      )?.[0]?.rows || 0;
    let minRows =
      get_brief_response?.response?.organization_info?.rules?.filter(
        (ru) => ru?.type === "min"
      )?.[0]?.rows || 0;
    const TotalRows = Number(maxRows) + Number(minRows);

    if (TotalRows) {
      Array.from(Array(TotalRows)).map((_, i) => {
        visualTotal += 1;
        running_total += 1;
        total += 1;
        feedbackLength += 1;
        _seq[running_total] = {
          id: running_total,
          quesType: feedbackTypeQuestion,
          skip: false,
          sno: running_total,
          buttonType: nextQuestionBtn,
          visualTotal,
          feedListNo: feedbackLength,
          clicked: false,
          response: false,
          buttonText: "Next",
        };

        let quesRefId = running_total;

        visualTotal += 1;
        running_total += 1;
        total += 1;
        _seq[running_total] = {
          id: running_total,
          quesType: followupAiTypeQuestion,
          skip: false,
          sno: running_total,
          buttonType: nextQuestionBtn,
          visualTotal,
          feedListNo: feedbackLength,
          clicked: false,
          response: false,
          quesRefId: quesRefId,
          buttonText: "Next",
        };
        return null;
        // return console.log(rr)
      });
    }

    let commLength = 0;
    if (askComments) {
      askComments.map((q, v) => {
        running_total += 1;
        commLength += 1;
        visualTotal += 1;
        total += 1;

        _seq[running_total] = {
          id: running_total,
          quesType: commentTypeQuestion,
          buttonType: nextQuestionBtn,
          skip: false,
          sno: running_total,
          visualTotal,
          comListNo: commLength,
          clicked: false,
          response: false,
          buttonText: "Next",
        };
        return null;
      });
    }



    

    // personalityQuestion
    if (showInSurveyPersonality) {
      let inPerListNo = 0;
      // visualTotal += 1
      running_total += 1;
      feedbackLength += 1;
      total += 1;
      _seq[running_total] = {
        id: running_total,
        quesType: introSectionTypeQuestion,
        emoji: "📝",
        title: "Getting to know you",
        text: [
          "In an effort to better contextualize the feedback we have received from you, we’d like to ask you a few questions about your personality. For the following 10 questions please rate the extent to which you agree or disagree with the statement.",
          "Your data is always kept private, and no one can access your individual responses to these questions.",
        ],
        buttonType: nextQuestionBtn,
        buttonText: "I agree",
        skip: false,
        sno: running_total,
        visualTotal,
        perListNo: inPerListNo,
        clicked: false,
        response: { response: true },
      };

      personalityQuestionList.map(() => {
        visualTotal += 1;
        running_total += 1;
        feedbackLength += 1;
        total += 1;
        inPerListNo += 1;
        _seq[running_total] = {
          id: running_total,
          quesType: personalityTypeQuestion,
          skip: false,
          sno: running_total,
          buttonType: nextQuestionBtn,
          visualTotal,
          perListNo: inPerListNo,
          clicked: false,
          response: false,
          buttonText: "Next",
        };
        return null;
      });
    }

    // let _outcomeQuestions = []
    // console.log("get_modular_questions?.modular_questions", get_modular_questions?.modular_questions)
    modSurveyRule.map((modSur) => {
      const ModQues =
        get_brief_response?.modular_questions[modSur?.value]?.questions;
      // console.log("ModQues", ModQues);
      // console.log("_outcomeQuestions", _outcomeQuestions)
      // console.log("modSur", get_modular_questions?.modular_questions[modSur?.value])
      // console.log("modSur", modSur)
      // category completed
      let catTotal = 0;
      if (ModQues) {
        // to enable intro screen to modules use the code below
        // running_total += 1
        // feedbackLength += 1
        // total += 1
        // _seq[running_total] = {
        // 	id: running_total,
        // 	quesType: introSectionTypeQuestion,
        // 	emoji: '📝',
        // 	title: 'Getting to know you',
        // 	text: [`In an effort to better contextualize the feedback we have received from you, we’d like to ask you a few questions about ${modSur?.name}. `],
        // 	buttonType: nextQuestionBtn,
        // 	buttonText: 'Next',
        // 	skip: false,
        // 	sno: running_total,
        // 	visualTotal,
        // 	clicked: false,
        // 	response: {response: true,},
        // }
        let _askOnRef = null;
        let _quesOnRef = null;
        ModQues.map((MainQues) => {
          //  check for AI follow up
          // console.log("MainQues", MainQues);
          _askOnRef = null;
          _quesOnRef = null;
          // loop through each question
          MainQues.questions.map((mq) => {
            if (mq?.ask_on_ref?.enabled) {
              if (_quesOnRef) {
                if (resetResponsesOnChange[_quesOnRef]) {
                  // If it exists, add the new property
                  resetResponsesOnChange[_quesOnRef].push(running_total);
                } else {
                  // If it doesn't exist, create a new object with the new property
                  resetResponsesOnChange[_quesOnRef] = [running_total];
                }
              }
              _askOnRef = mq?.ask_on_ref;
              // get this question id from outcomeQuestion
              // console.log("_askOnRef running_total", running_total, _askOnRef)
              const [q, s] = _askOnRef?.ref_question_id.split(".").map(Number);
              const filteredResults = Object.keys(_seq)
                .filter(
                  (key) =>
                    _seq[key].quesType === "modularOutcomeTypeQuestion" &&
                    _seq[key]?.question?.q === q &&
                    _seq[key]?.question?.s === s
                )
                .map((key) => _seq[key]);

              _quesOnRef = filteredResults?.[0]?.id;

              // Check if _quesOnRef exists in _seq
              if (_seq[_quesOnRef]) {
                // If it exists, add the new property
                _seq[_quesOnRef].resetOnChange = true;
              } else {
                // If it doesn't exist, create a new object with the new property
                _seq[_quesOnRef] = { resetOnChange: true };
              }
            }

            if (mq?.ai_follow_up?.enabled) {

              _askOnRef = mq?.ask_on_ref;
              // get this question id from outcomeQuestion
              // console.log("_askOnRef running_total", running_total, _askOnRef)
              const [q, s] = _askOnRef?.ref_question_id.split(".").map(Number);
              const filteredResults = Object.keys(_seq)
                .filter(
                  (key) =>
                    _seq[key].quesType === "modularOutcomeTypeQuestion" &&
                    _seq[key]?.question?.q == q &&
                    _seq[key]?.question?.s == s
                )
                .map((key) => _seq[key]);

              _quesOnRef = filteredResults?.[0]?.id;

              // add the pre ai follow up question.
              running_total += 1;
              total += 1;
              visualTotal += 1;
              catTotal += 1;
              if (_quesOnRef) {
                if (resetResponsesOnChange[_quesOnRef]) {
                  // If it exists, add the new property
                  resetResponsesOnChange[_quesOnRef].push(running_total);
                } else {
                  // If it doesn't exist, create a new object with the new property
                  resetResponsesOnChange[_quesOnRef] = [running_total];
                }
              }
              // console.log("_askOnRef 1160 a", _askOnRef);
              // console.log("running_total 1160 a", running_total);
              // console.log("filteredResults", filteredResults?.[0])
              // console.log("_quesOnRef 1160 b" , _quesOnRef)

              _seq[running_total] = {
                quesType: ModularSurveyQuestionTypes[modSur?.value],
                aiFollowUp: true,
                followUpQuestions: true,
                sno: running_total,
                q: mq?.q,
                s: mq?.s,
                id: running_total,
                sort_order: running_total,
                question: mq,
                buttonType: nextQuestionBtn,
                name: `${MainQues?.name}`,
                uniqueName: `${modSur?.value}-${MainQues?.name}`,
                total: running_total,
                askOnRef: _askOnRef,
                askOnRefQID: _quesOnRef,
                catTotal: catTotal,
                skip: false,
                visualTotal,
                value: modSur?.value,
                clicked: false,
                response: false,
                buttonText: "Next",
                fr_name:MainQues?.fr_name,
                es_name:MainQues?.es_name,
              };

              const _aiFollowUpList = [];

              if (mq?.ai_follow_up?.questions?.length) {
                // error in this
                mq.ai_follow_up.questions.map((fq) => {
                  _aiFollowUpList.push(running_total);
                  running_total += 1;
                  total += 1;
                  visualTotal += 1;
                  catTotal += 1;
                  if (_quesOnRef) {
                    //  console.log("resetResponsesOnChange[_quesOnRef]", resetResponsesOnChange[_quesOnRef], running_total)
                    if (resetResponsesOnChange[_quesOnRef]) {
                      // If it exists, add the new property
                      resetResponsesOnChange[_quesOnRef].push(running_total);
                    } else {
                      // If it doesn't exist, create a new object with the new property
                      resetResponsesOnChange[_quesOnRef] = [running_total];
                    }
                  }

                _askOnRef = mq?.ask_on_ref;
                // get this question id from outcomeQuestion
                // console.log("_askOnRef running_total", running_total, _askOnRef)
                const [q, s] = _askOnRef?.ref_question_id.split(".").map(Number);
                const filteredResults = Object.keys(_seq)
                  .filter(
                    (key) =>
                      _seq[key].quesType === "modularOutcomeTypeQuestion" &&
                      _seq[key]?.question?.q == q &&
                      _seq[key]?.question?.s == s
                  )
                  .map((key) => _seq[key]);

                _quesOnRef = filteredResults?.[0]?.id;

                  // console.log("_askOnRef 1229 b", _askOnRef);
                  // console.log("running_total 1229 b", running_total);
                  // // console.log("filteredResults", filteredResults)
                  // console.log("_quesOnRef 1229 b", _quesOnRef)

                  _seq[running_total] = {
                    quesType: multiAiFollowupCommentQuestion,
                    aiFollowUp: true,
                    followUpQuestions: [..._aiFollowUpList], // Use _aiFollowUpList directly,
                    sno: running_total,
                    q: mq?.q,
                    s: mq?.s,
                    askOnRef: _askOnRef,
                    askOnRefQID: _quesOnRef,
                    q_id: fq?.id,
                    scale: mq?.scale,
                    id: running_total,
                    sort_order: running_total,
                    question: fq?.question,
                    fr: fq?.fr,
                    es:fq?.es,
                    buttonType: nextQuestionBtn,
                    name: `${MainQues?.name}-aiFollowUp`,
                    uniqueName: `${modSur?.value}-${MainQues?.name}-aiFollowUp`,
                    total: running_total,
                    catTotal: catTotal,
                    skip: false,
                    visualTotal,
                    value: modSur?.value,
                    clicked: false,
                    response: false,
                    buttonText: "Next",
                  };
                  return null;
                });
              }
              _askOnRef = null;
              _quesOnRef = null;
              // console.log("mq ---", running_total,  mq)
              return null;
              // Update ifollowupquestions after the loop
            }
            // console.log(modSur);
            // console.log(mq);
            running_total += 1;
            total += 1;
            visualTotal += 1;
            catTotal += 1;

            _askOnRef = mq?.ask_on_ref;
            //   // get this question id from outcomeQuestion
            
            let [q, s] = [0,0]
            if (_askOnRef?.ref_question_id.split(".")) {
              [q, s] = _askOnRef?.ref_question_id.split(".");
            }
            
              // const [q, s] = _askOnRef?.ref_question_id.split(".").map(Number);
              const filteredResults = Object.keys(_seq)
                .filter(
                  (key) =>
                    _seq[key].quesType === "modularOutcomeTypeQuestion" &&
                    // eslint-disable-next-line eqeqeq
                    _seq[key]?.question?.q == q &&
                    // eslint-disable-next-line eqeqeq
                    _seq[key]?.question?.s == s
                )
                .map((key) => _seq[key]);

              _quesOnRef = filteredResults?.[0]?.id;
            
            // console.log("_askOnRef 1277 c", mq);
            // console.log("_askOnRef 1277 c", _askOnRef);
            // console.log("running_total 1277 c", running_total);
            // console.log("filteredResults 1277 c", filteredResults)
            // console.log("_quesOnRef 1277 c", _quesOnRef)

            return (_seq[running_total] = {
              quesType: ModularSurveyQuestionTypes[modSur?.value],
              aiFollowUp: false,
              followUpQuestions: false,
              sno: running_total,
              q: mq?.q,
              s: mq?.s,
              id: running_total,
              sort_order: running_total,
              question: mq,
              buttonType: nextQuestionBtn,
              name: `${MainQues?.name}`,
              uniqueName: `${modSur?.value}-${MainQues?.name}`,
              total: running_total,
              askOnRef: _askOnRef,
              askOnRefQID: _quesOnRef,
              catTotal: catTotal,
              skip: false,
              visualTotal,
              value: modSur?.value,
              clicked: false,
              response: false,
              buttonText: "Next",
              fr_name:MainQues?.fr_name,
              es_name:MainQues?.es_name
            });
          });

          _askOnRef = null;
          _quesOnRef = null;
          return null;
        });
      }

      return null;
    });

    // final submit
    running_total += 1;
    total += 1;
    _seq[running_total] = {
      id: running_total,
      quesType: submitTypeQuestion,
      buttonType: submitSurvey,
      buttonText: "Submit",
      skip: false,
      sno: running_total,
      visualTotal,
      clicked: false,
      response: { response: true },
    };

    // const setresetResponsesOnChange = new Set(resetResponsesOnChange);

    setTotalQuestions({
      total: total,
      introQuestion,
      visualTotal,
      runningTotal: running_total,
      introduction: quesLength,
      questions: quesLength,
      category: CatLength, // add primaryCategory
      comments: commLength,
      feedback: feedbackLength,
      sequence: _seq,
      resetResponsesOnChange: resetResponsesOnChange,
    });

    // setQuestionSequenceMap(_seq)
    // console.log("question seq ran")
  }, [
    questionList,
    get_brief_response.response.organization_info,
    get_brief_response.modular_questions,
    categoryQues,
    employeeCategory,
    organizationInfo,
    askComments,
    checkRole,
    modSurveyRule,
    showInSurveyPersonality,
    organizationName,
  ]);

  // console.log("currentQuestionDet.id", currentQuestionDet?.id);
  // console.log("questionSequenceMap", questionSequenceMap);
  // console.log("questionSequenceMap", questionSequenceMap[currentQuestionNo]);
  // console.log(
  //   "totalQuestions.resetResponsesOnChange",
  //   totalQuestions.resetResponsesOnChange
  // );
  // console.log(
  //   "totalQuestions?.runningTotal $$$$$$$$$$$$$$$$$$$$$$$$$$$$",
  //   totalQuestions?.runningTotal
  // );

  useEffect(() => {
    if (currentQuestionNo > 1 && questionSequenceMap) {
      setCurrentQuestionDet(questionSequenceMap[currentQuestionNo]);
    } else {
      setCurrentQuestionDet(totalQuestions.sequence[1]);
    }
  }, [questionSequenceMap, currentQuestionNo, totalQuestions]);

  // console.log("currentQuestionNo", currentQuestionNo);

  function nextQuestion() {
    let _questionNo = currentQuestionNo + 1;
    // this is for first question
    if (currentQuestionNo === 1) {
      return 2;
    }
    while (_questionNo <= totalQuestions?.runningTotal) {
      if (questionSequenceMap[_questionNo]?.skip) {
        // console.log("_checkFdbk ++++++++++++ SKIP", currentQuestionNo, _questionNo)
        _questionNo++;
      } else {
        const _checkFdbk = questionSequenceMap[_questionNo]?.response?.skip;
        // check if follow up and feedback is skipped
        if (_checkFdbk) {
          // console.log("_checkFdbk ++++++++++++ RESPONSE SKIP", currentQuestionNo, _questionNo)
          _questionNo++;
        } else {
          // console.log("_checkResp ++++++++++++ OK", currentQuestionNo, _questionNo)
          return _questionNo;
        }
      }
    }

    return currentQuestionNo;
  }

  const handleNext = () => {
    const timer1 = setTimeout(() => {
      // this whole block is to check if the question is skipped
      // this block checks if the question is skipped

      if (currentQuestionNo) {
        const _currQuestion = questionSequenceMap[currentQuestionNo];

        if (_currQuestion) {
          // Skip the entire block if _currQuestion is skipped or response is skipped or response is empty
          // Continue if any of the specified conditions is true
          if (
            _currQuestion?.skip ||
            _currQuestion.response?.skip ||
            _currQuestion?.response?.clicked ||
            _currQuestion.response?.response !== undefined 
          ) {
            // console.log("Continue with the code");
            // Continue with the code
          } else {
            // Return from handleNext
            // console.log("Conditions not met - Returning from handleNext");
            return;
          }
        }
      }
      // console.log("currentQuestionNo handleNext >>>>>>>>>>>>>>>>>>>>>>>>>> ");
      const NextQues = nextQuestion();
      if (currentQuestionNo < NextQues) {
        setDirection("slide");
        // persist with api

        if (briefResponseId) {
          dispatch({
            type: SG_UPDATE_BRIEF_RESPONSE,
            payload: {
              id: briefResponseId,
              query: `?token=${token}`,
              validation_token: LOCAL_VALIDATION_TOKEN,
              ...(formPayload && {
                stored_response: {
                  ...formPayload,
                  validation_token: LOCAL_VALIDATION_TOKEN,
                },
              }),
            },
          });
        }
        setQuestionNo(NextQues);
        // // setVisualQuestionNo(visualQuestionNo + 1)
        setVisualQuestionNo(questionSequenceMap[NextQues]?.visualTotal);
        // console.log(
        //   "NextQues handleNext ++++++++++++++++++++++++++++++++++++",
        //   NextQues
        // );
        setCurrentQuestionNo(NextQues);
      }
    }, 10);

    // console.log(
    //   "currentQuestionNo handleNext ++++++++^^^^^^^^^^^^^^^^",
    //   currentQuestionNo
    // );
    return () => clearTimeout(timer1);
  };

  function prevQuestion() {
    let _questionNo = currentQuestionNo - 1;
    while (_questionNo >= 1) {
      if (totalQuestions.sequence[_questionNo]?.skip) {
        _questionNo--;
      } else {
        const _checkFdbk = questionSequenceMap[_questionNo]?.response?.skip;
        const _checkResp = questionSequenceMap[_questionNo]?.response;
        // check if follow up and feedback is skipped
        if (_checkFdbk) {
          _questionNo--;
        } else if (!_checkResp) {
          return _questionNo;
        } else {
          setQuestionNo(_questionNo);
          // setVisualQuestionNo(visualQuestionNo - 1)
          setVisualQuestionNo(currentQuestionDet?.visualTotal);
          return _questionNo;
        }
      }
    }
    return currentQuestionNo;
  }

  const handleBack = () => {
    setDisableNext(false)
    if (currentQuestionNo > 0) {
      setDirection("slide-back");
      // persist with api
      const timer1 = setTimeout(() => {
        if (briefResponseId) {
          dispatch({
            type: SG_UPDATE_BRIEF_RESPONSE,
            payload: {
              id: briefResponseId,
              query: `?token=${token}`,
              validation_token: LOCAL_VALIDATION_TOKEN,
              ...(formPayload && {
                stored_response: {
                  ...formPayload,
                  validation_token: LOCAL_VALIDATION_TOKEN,
                },
              }),
            },
          });
        }
        setCurrentQuestionNo(prevQuestion());
      }, 10);
      return () => clearTimeout(timer1);
    }
  };

  function resetValidationToken(newValidationToken) {
    LOCAL_VALIDATION_TOKEN = newValidationToken;
  }
  // console.log(formPayload)

  useEffect(() => {
    if (get_brief_response?.response?.id) {
      setNoResponse(false);
    } else {
      setNoResponse(true);
    }
  }, [get_brief_response]);

  useEffect(() => {
    const timer1 = setTimeout(() => {
      setDelayedShow(get_brief_response?.pending);
    }, 1000);
    return () => clearTimeout(timer1);
  }, [get_brief_response]);

  // console.log("OR", get_brief_response?.pending | get_modular_questions?.pending)
  // console.log("AND", get_brief_response?.pending & get_modular_questions?.pending)
  useEffect(() => {
    // Run! Like go get some data from an API.
    if (token) {
      dispatch({
        type: SG_START_BRIEF_SURVEY,
        payload: `?token=${token}`,
      });
    }
  }, [dispatch, token]);

  // console.log("surveyModularResp", surveyModularResp)
  // final submit
  function submitAnswer() {
    const CompleteCategories = {
      ...questionAnswers?.categories,
      ...prevFoundResponse,
      ...neverAskResponse,
    };
    if (CompleteCategories) {
      CompleteCategories[0] = primaryCategory;
    }
    if (totalQuestions?.questions && questionAnswers?.questions) {
      if (
        totalQuestions?.questions >
        Object.values(questionAnswers?.questions).length
      ) {
        // console.log("error found")
        setSubmitError(ERROR_ON_SUBMISSION);
        return;
      }
    }

    let _completeCategories = [];
    if (CompleteCategories) {
      _completeCategories = Object.values(CompleteCategories);
    }

    let _completeQuestions = [];
    if (questionAnswers.questions) {
      _completeQuestions = Object.values(questionAnswers.questions);
    }

    let _completeComments = [];
    if (questionAnswers.comments) {
      _completeComments = Object.values(questionAnswers.comments);
    }

    // console.log( {
    //   			questions: {
    //   				questions: _completeQuestions,
    //   				categories: _completeCategories,
    //   				comments: _completeComments,
    //   			},
    //   			total_question_map: totalQuestions,
    //   			feedback: surveyFeedbackResp,
    //   			survey_personality: surveyPersonalityResp,
    //   			modular_response: surveyModularResp,
    //   			version: 2.0,
    //   			version_description: "Culture Audit version which includes revised chat gpt and home list of all questions",
    //   		})

    dispatch({
      type: SG_POST_BRIEF_RESPONSE,
      payload: {
        survey_token: briefResponse?.survey_token,
        debrief_schedule: briefResponse?.debrief_schedule,
        employee: briefResponse?.employee,
        validation_token: null,
        response: {
          questions: {
            questions: _completeQuestions,
            categories: _completeCategories,
            comments: _completeComments,
          },
          total_question_map: totalQuestions,
          feedback: surveyFeedbackResp,
          survey_personality: surveyPersonalityResp,
          modular_response: surveyModularResp,
          version: 2.0,
          version_description:
            "Culture Audit version which includes revised chat gpt and home list of all questions",
        },
        full_response: {
          isBrowser: isBrowser,
          isMobile: isMobile,
          browser: deviceDetected.browserName,
          deviceType,
          osVersion,
          osName,
          deviceDetected: deviceDetected,
          response: {
            questions: {
              questions: _completeQuestions,
              categories: _completeCategories,
              comments: _completeComments,
            },
            total_question_map: totalQuestions,
            feedback: surveyFeedbackResp,
            survey_personality: surveyPersonalityResp,
            modular_response: surveyModularResp,
            version: 2.0,
            version_description:
              "Culture Audit version which includes revised chat gpt and home list of all questions",
          },
        },
      },
    });
    dispatch({
      type: RESET_VALIDATION_TOKEN,
      payload: null,
    });
    dispatch({
      type: CLEAR_ALL,
      payload: null,
    });
    localStorage.clear();

    setSurveyFinished(true);
  }

  function StartSurvey() {
    const _questionSequenceMap = totalQuestions?.sequence;
    _questionSequenceMap[currentQuestionNo].clicked = true;
    _questionSequenceMap[currentQuestionNo].response = true;
    let NewValidationToken = uuidv4();

    if (LOCAL_VALIDATION_TOKEN) {
      NewValidationToken = LOCAL_VALIDATION_TOKEN;
    } else {
      localStorage.setItem("validation_token", NewValidationToken);
    }

    setFormPayload({
      current_question: 1,
      current_answer: 0,
      high_answer: 0,
      answers: {
        categories: {},
        questions: {},
        comments: {},
      },
      complete: false,
      intro_completed: true,
      modular_response: {},
      survey_personality: {},
      feedback: [],
      questionSequenceMap: _questionSequenceMap,
      validation_token: NewValidationToken,
    });

    dispatch({
      type: NEW_VALIDATION_TOKEN,
      payload: NewValidationToken,
    });

    setQuestionSequenceMap(_questionSequenceMap);
    handleNext();
  }

  // console.log("get_brief_response?.feedback", get_brief_response?.feedback)
  // console.log("get_brief_response?.survey_personality", get_brief_response?.survey_personality)
  // console.log("get_brief_response?.modular_response", get_brief_response?.modular_response)
  // console.log("get_brief_response?.answers", get_brief_response?.answers)
  // console.log("currentQuestionNo", currentQuestionNo)

  // console.log("currentQuestionDet", currentQuestionDet)
  // console.log("surveyModularResp", surveyModularResp)
  // console.log("categoryQues", categoryQues)

  // updated answers
  function saveUpdatedAnswers(curQuesNo, response, typeOf) {
    // console.log("saveUpdatedAnswers" ,curQuesNo, response, typeOf)
    let _feedbackResp = [];
    if (surveyFeedbackResp) {
      _feedbackResp = surveyFeedbackResp;
    }

    const _questionAnswers = questionAnswers;

    let _survey_personality = {};
    if (surveyPersonalityResp) {
      _survey_personality = surveyPersonalityResp;
    }

    let _modular_response = {};
    if (surveyModularResp) {
      _modular_response = surveyModularResp;
    }

    const _curQuesDet = questionSequenceMap[curQuesNo];

    if (typeOf === categoryTypeQuestion) {
      _questionAnswers.categories[curQuesNo] = response;
    }

    if (typeOf === questionTypeQuestion) {
      _questionAnswers.questions[curQuesNo] = response;
    }

    if (typeOf === commentTypeQuestion) {
      _questionAnswers.comments[curQuesNo] = response;
    }

    if (typeOf === feedbackTypeQuestion) {
      _feedbackResp = _feedbackResp.filter(
        (fb) => fb?.survey_q_id !== response?.survey_q_id
      );
      _feedbackResp.push(response);
    }

    if (typeOf === followupAiTypeQuestion) {
      _feedbackResp = _feedbackResp.filter(
        (fb) => fb?.survey_q_id !== response?.survey_q_id
      );
      _feedbackResp.push(response);
    }

    if (typeOf === personalityTypeQuestion) {
      _survey_personality[_curQuesDet?.perListNo] = response;
    }

    // console.log("modularOutcomeTypeQuestion", modularOutcomeTypeQuestion)

    if (typeOf === modularOutcomeTypeQuestion) {
      //   console.log(response)
      const modularQuestion = get_brief_response?.surveyComponents.find(
        (sc) => sc.value === _curQuesDet?.value
      );
      if (_modular_response[_curQuesDet?.value]?.responses) {
        let _modResps = _modular_response[_curQuesDet?.value]?.responses;
        // match ques_order
        _modResps = _modResps.filter(
          (fb) => fb?.ques_order !== _curQuesDet?.id
        );
        _modResps.push(response);
        _modular_response[_curQuesDet?.value].responses = _modResps;
      } else {
        _modular_response[_curQuesDet?.value] = {
          id: modularQuestion?.id,
          label: modularQuestion?.name,
          value: modularQuestion?.value,
          on_date: new Date(),
          ask_again: 2,
          completed: true,
          responses: [response],
          briefResponseId: get_brief_response?.id,
          brief_schedule_id: get_brief_response?.debrief_schedule,
        };
      }
      //   console.log("modularQuestion", modularQuestion)
    }

    // console.log("_feedbackResp", _feedbackResp)
    if (typeOf === modularValuesTypeQuestion) {
      const modularQuestion = get_brief_response?.surveyComponents.find(
        (sc) => sc.value === _curQuesDet?.value
      );
      if (_modular_response[_curQuesDet?.value]?.responses) {
        let _modResps = _modular_response[
          _curQuesDet?.value
        ]?.responses?.filter((fb) => fb?.ques_order !== _curQuesDet?.id);
        // match ques_order

        _modResps.push(response);
        _modular_response[_curQuesDet?.value].responses = _modResps;
      } else {
        _modular_response[_curQuesDet?.value] = {
          id: modularQuestion?.id,
          label: modularQuestion?.name,
          value: modularQuestion?.value,
          on_date: new Date(),
          ask_again: 2,
          completed: true,
          responses: [response],
          briefResponseId: get_brief_response?.id,
          brief_schedule_id: get_brief_response?.debrief_schedule,
        };
      }
    }

    if (typeOf === modularCommentTypeQuestion) {
      const modularQuestion = get_brief_response?.surveyComponents.find(
        (sc) => sc.value === _curQuesDet?.value
      );
      if (_modular_response[_curQuesDet?.value]?.responses) {
        let _modResps = _modular_response[
          _curQuesDet?.value
        ]?.responses?.filter((fb) => fb?.ques_order !== _curQuesDet?.id);
        // match ques_order

        _modResps.push(response);
        _modular_response[_curQuesDet?.value].responses = _modResps;
      } else {
        _modular_response[_curQuesDet?.value] = {
          id: modularQuestion?.id,
          label: modularQuestion?.name,
          value: modularQuestion?.value,
          on_date: new Date(),
          ask_again: 2,
          completed: true,
          responses: [response],
          briefResponseId: get_brief_response?.id,
          brief_schedule_id: get_brief_response?.debrief_schedule,
        };
      }
    }

    // multiAiFollowupCommentQuestion
    if (typeOf === multiAiFollowupCommentQuestion) {
      const modularQuestion = get_brief_response?.surveyComponents.find(
        (sc) => sc.value === _curQuesDet?.value
      );
      if (_modular_response[_curQuesDet?.value]?.responses) {
        let _modResps = _modular_response[
          _curQuesDet?.value
        ]?.responses?.filter((fb) => fb?.ques_order !== _curQuesDet?.id);
        // match ques_order

        _modResps.push(response);
        _modular_response[_curQuesDet?.value].responses = _modResps;
      } else {
        _modular_response[_curQuesDet?.value] = {
          id: modularQuestion?.id,
          label: modularQuestion?.name,
          value: modularQuestion?.value,
          on_date: new Date(),
          ask_again: 2,
          completed: true,
          responses: [response],
          briefResponseId: get_brief_response?.id,
          brief_schedule_id: get_brief_response?.debrief_schedule,
        };
      }
      dispatch({
        type: CLEAR_CHAT_GPT_SUMMARY,
      });
    }

    let _complete = false;
    if (curQuesNo + 1 >= totalQuestions.visualTotal) {
      _complete = true;
    }

    setQuestionAnswers(_questionAnswers);
    setSurveyPersonalityResp(_survey_personality);
    setSurveyFeedbackResp(_feedbackResp);

    const _questionSequnce = questionSequenceMap;
    _questionSequnce[curQuesNo]["clicked"] = true;
    _questionSequnce[curQuesNo]["response"] = response;

    setQuestionSequenceMap(_questionSequnce);

    setFormPayload({
      visual_question: _curQuesDet?.visualTotal,
      current_question: curQuesNo,
      current_answer: curQuesNo,
      high_answer:
        curQuesNo > briefResponse?.high_answer
          ? curQuesNo
          : briefResponse?.high_answer,
      answers: _questionAnswers,
      complete: _complete,
      validation_token: LOCAL_VALIDATION_TOKEN,
      survey_personality: _survey_personality,
      modular_response: _modular_response,
      questionSequenceMap: _questionSequnce,
      feedback: _feedbackResp,
    });

    setSurveyModularResp(_modular_response);

    if (askNextQuestion) {
      const quesSeq = questionSequenceMap[curQuesNo + 1];
      if (quesSeq?.response) {
        if (quesSeq.response.prompt) {
          quesSeq.response.prompt = null;
        }
        if (quesSeq.response.prompt_question) {
          quesSeq.response.prompt_question = null;
        }
        if (quesSeq.response.feedback) {
          quesSeq.response.feedback = null;
        }
        if (quesSeq.response.skipped) {
          quesSeq.response.skipped = false;
        }
      }
    }
  }

  useEffect(() => {
    if (get_errors?.errors?.error?.request?.response) {
      let RESP = JSON.parse(get_errors?.errors?.error?.request?.response);
      setSubmitError(RESP?.detail);
    }
  }, [get_errors]);

  // console.log(direction)

  function skipNextQuestion() {
    console.log(
      "skipNextQuestion ++++++++++++++++++++++++++++++++++++ *************************"
    );
    setDirection("slide");
    const _refQues = questionSequenceMap[currentQuestionNo];
    const _curQues = questionSequenceMap[currentQuestionNo + 1];
    let ResponseObj = {
      survey_q_id: _curQues.id,
      dimension: _refQues?.response?.dimension,
      factor: _refQues?.response?.factor,
      fr: _refQues?.response?.fr,
      es: _refQues?.response?.es,
      id: _refQues?.response?.id,
      q: _refQues?.response?.q,
      ques_order: _refQues?.response?.ques_order,
      question: _refQues?.response?.question,
      // response: _refQues?.response?.feedback,
      reverse: _refQues?.response?.reverse,
      role: _refQues?.response?.role,
      type: _refQues?.response?.type,
      // prompt: "skipped",
      // prompt_question: "skipped",
      // feedback: '',
      skipped: true,
    };
    let _questionNo = currentQuestionNo + 2;

    saveUpdatedAnswers(_curQues.id, ResponseObj, followupAiTypeQuestion);

    // setCurrentQuestionDet(questionSequenceMap[_questionNo])
    setCurrentQuestionNo(_questionNo);

    setQuestionNo(_questionNo);
    setVisualQuestionNo(questionSequenceMap[_questionNo]?.visualTotal);

    if (currentQuestionNo < _questionNo) {
      // persist with api
      if (briefResponseId) {
        dispatch({
          type: SG_UPDATE_BRIEF_RESPONSE,
          payload: {
            id: briefResponseId,
            query: `?token=${token}`,
            validation_token: LOCAL_VALIDATION_TOKEN,
            ...(formPayload && { stored_response: formPayload }),
          },
        });
      }
    }
  }

  // console.log(currentQuestionDet)

  const renderModule = (currentQuestionDet) => {
    switch (currentQuestionDet?.quesType) {
      case introductionTypeQuestion:
        return (
          <Introduction
            id={currentQuestionDet.intro_id}
            currentQuestion={currentQuestionNo}
            total={currentQuestionDet.total}
            language={language}
            ai_disclaimer={organizationInfo?.ai_disclaimer}
            sort_order={currentQuestionDet?.survey_sort_order}
            disableNext={(v)=>setDisableNext(v)}
            title={
              organizationInfo?.intro_title
                ? get_brief_response?.response?.organization_name
                : false
            }
          />
        );

      case categoryTypeQuestion:
        return (
          <Categories
            question={currentQuestionDet.question}
            categories={currentQuestionDet.categories}
            description={currentQuestionDet.description}
            categoryQues={categoryQues}
            currentQuestionDet={currentQuestionDet}
            saveUpdatedAnswers={saveUpdatedAnswers}
            organizationName={organizationName}
            // existingResponse={existingResponse}
            // employeeCategory={employeeCategory}
            language={language}
          />
        );
      case questionTypeQuestion:
        return (
          <Question
            question={
              questionList.filter(
                (q) => q.order === currentQuestionDet?.quesListNo
              )[0]
            }
            saveUpdatedAnswers={saveUpdatedAnswers}
            currentQuestionDet={currentQuestionDet}
            language={language}
            qualifier={organizationInfo?.survey_qualifier}
            team_qualifier={organizationInfo?.team_qualifier}
            teamName={teamName}
          />
        );
      case commentTypeQuestion:
        return (
          <Comments
            data={currentQuestionDet}
            question={
              askComments.filter(
                (q) => q.id === currentQuestionDet?.comListNo
              )[0]
            }
            saveUpdatedAnswers={saveUpdatedAnswers}
            currentQuestionDet={currentQuestionDet}
            language={language}
          />
        );
      case feedbackTypeQuestion:
        // AI feedback question ln 1188
        return (
          <Feedback
            ruleFeedback={ruleFeedback[currentQuestionDet?.feedListNo - 1]}
            saveUpdatedAnswers={saveUpdatedAnswers}
            currentQuestionDet={currentQuestionDet}
            language={language}
            skipNextQuestion={skipNextQuestion}
            setAskNextQuestion={setAskNextQuestion}
            sortOrder={sortOrder}
          />
        );
      case followupAiTypeQuestion:
        // AI chat gpt follow up question for max -min feedback only
        // console.log(currentQuestionDet)
        const refFollowUpQues =
          questionSequenceMap[currentQuestionDet?.quesRefId];
        return (
          <FollowUp
            currentQuestionDet={currentQuestionDet}
            refQues={refFollowUpQues}
            saveUpdatedAnswers={saveUpdatedAnswers}
            handleNext={handleNext}
            language={language}
            sortOrder={sortOrder}
          />
        );

      case personalityTypeQuestion:
        return (
          <PersonalityChoice
            currentQuestionDet={currentQuestionDet}
            saveUpdatedAnswers={saveUpdatedAnswers}
            language={language}
          />
        );
      case modularOutcomeTypeQuestion:
        return (
          <QuestionModular
            resetResponsesOnChange={totalQuestions?.resetResponsesOnChange}
            questionSequenceMap={questionSequenceMap}
            question={currentQuestionDet?.question}
            saveUpdatedAnswers={saveUpdatedAnswers}
            currentQuestionDet={currentQuestionDet}
            language={language}
            teamName={teamName}
          />
        );
      case modularValuesTypeQuestion:
        // question, saveUpdatedAnswers, currentQuestionDet
        return (
          <QuestionValues
            question={currentQuestionDet?.question}
            currentQuestionDet={currentQuestionDet}
            saveUpdatedAnswers={saveUpdatedAnswers}
            language={language}
          />
        );
      case modularCommentTypeQuestion:
        return (
          <QuestionComments
            questionSequenceMap={questionSequenceMap}
            direction={direction}
            handleNext={handleNext}
            handleBack={handleBack}
            currentQuestionDet={currentQuestionDet}
            saveUpdatedAnswers={saveUpdatedAnswers}
            language={language}
          />
        );
      case multiAiFollowupCommentQuestion:
        // AI multi follow up
        
        return (
          <MultiFollowUp
            localToken={LOCAL_VALIDATION_TOKEN}
            questionSequenceMap={questionSequenceMap}
            currentQuestionDet={currentQuestionDet}
            saveUpdatedAnswers={saveUpdatedAnswers}
            handleNext={handleNext}
            language={language}
          />
        );
      case introSectionTypeQuestion:
        return <IntroSection data={currentQuestionDet} />;
      // incase the survey was completed earlier or token's missing or something
      // case completedTypeQuestion:
      //   return <Completed title={storedTitle} language={language}/>;
      case submitTypeQuestion:
        return <Submit language={language}/>;
      default:
        return null;
    }
  };

  if (delayedShow) {
    return (
     
          <ModuleContainer className="module-wrapper" key={currentQuestionNo}>
            <LogoAnimation />
          </ModuleContainer>

    );
  }

  if (surveyFinished) {
    return (
      
          <ModuleContainer className="module-wrapper" key={currentQuestionNo}>
            <Completed
              statusText={THANKYOU_MESSAGE[language]}
              title={storedTitle}
              language={language}
              whitelabel={organizationInfo}
            />
          </ModuleContainer>
       
    );
  }

  if (surveyDeleted) {
    return (
      
          <ModuleContainer className="module-wrapper" key={currentQuestionNo}>
            <Completed
              statusText={words["completed_on"][language]}
              completedOn={surveyDeletedOn}
              language={language}
              surveyStatus={surveyStatus}
              title={storedTitle}
            />
          </ModuleContainer>
        
    );
  }

  // // ask for existing data in storage
  if (currentQuestionNo > 1 && storedResponseFound) {
    return (
      <AuditExistingDataFound
        setQuestionNo={setQuestionNo}
        setVisualQuestionNo={setVisualQuestionNo}
        token={token}
        briefResponseId={briefResponseId}
        resetValidationToken={resetValidationToken}
        storedResponseFound={storedResponseFound}
        storedResponse={storedResponse}
        setCurrentQuestionNo={setCurrentQuestionNo}
        setCurrentQuestionDet={setCurrentQuestionDet}
        setQuestionSequenceMap={setQuestionSequenceMap}
        setShowingStoredResponse={setShowingStoredResponse}
        language={language}
      />
    );
  }

  if (!token | !get_brief_response.id | noResponse) {
    return (

          <ModuleContainer className="module-wrapper" key={currentQuestionNo}>
            <Completed
              statusText={MISSING_OR_INVALID_TOKEN}
              language={language}
              title={get_brief_response?.response?.questions?.title}
            />
          </ModuleContainer>
    );
  }

  return (
    <SurveyContainer>
      {/* progress bar when it not introduction */}
      {currentQuestionNo > totalQuestions?.introQuestion && !surveyFinished && (
        <ProgressBar
          currentQuestion={currentQuestionDet?.visualTotal}
          totalQuestions={totalQuestions?.visualTotal}
          quesId={currentQuestionDet?.id}
          language={language}
          color={organizationInfo?.progress_bar}
          color2={organizationInfo?.progress_bar2}
        />
      )}

      {/* back button to take one question back when it not introduction */}
      {currentQuestionNo > 1 && currentQuestionNo <= totalQuestions?.introQuestion && (
        <Back onClick={handleBack}>
          <Icon name="chevron left" />{" "}
        </Back>
      )}

  {showLanguage && <Language language={language} setLanguage={setLanguage} languages={languages}/>}

     
          <ModuleContainer
            className="module-wrapper"
            selected={currentQuestionDet?.quesType}
            key={currentQuestionNo}
            height={height}
          >
            {renderModule(currentQuestionDet)}
          </ModuleContainer>
    

      <ButtonContainer>
        <NavigationButtons
          introQuestion={totalQuestions?.introQuestion}
          currentQuestionDet={currentQuestionDet}
          handleBack={handleBack}
          handleNext={handleNext}
          surveyFinished={surveyFinished}
          StartSurvey={StartSurvey}
          formPayload={formPayload}
          submitAnswer={submitAnswer}
          language={language}
          color={organizationInfo?.progress_bar}
          disabledNext={disableNext}
        />
        {/* {renderButtons(questionsList[currentQuestion])} */}
      </ButtonContainer>
    </SurveyContainer>
  );
};

export default AuditSurvey;

const SurveyContainer = styled.div`
  width: 100%;
  min-height: calc(var(--vh, 1vh) * 100);
  display: flex;
  flex-direction: column;
  background-color: #f8f9fa;
  position: relative;
  font-family: "Raleway", sans-serif;


  @media (max-width: 768px) {
    height:100%;
    padding-top:50px;
    padding-bottom:0px;
  }
`;

const SurveyContent = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;
// ...

const ButtonContainer = styled.div`

  display: flex;
  justify-content: space-between;
  padding: 1rem;
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

// ...

const ModuleContainer = styled.div`
 
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
  min-height:${props=>`calc(${props.height}px - 130px)`};
  padding:20px;
   opacity:0;

    animation: ${fadeIn} 1s ease-out forwards;
   animation-delay: ${({ delay }) => delay}s; // Use props to set delay

   @media(min-height:768px) and (min-width:768px){
    min-height:calc(100vh - 90px);
   }
`;

const Back = styled.div`
  position: absolute;
  left: 28px;
  top: 20px;
  cursor: pointer;
  font-size: 20px;
  width: 50px;
  height: 50px;
  z-index: 10000;
  color: #2a3039;
`;
